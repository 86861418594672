<template>
  <!-- 新闻分类 -->
  <div>
    <!-- 顶部分类 跳转 -->
    <div class="flex">
      <div>
        <span>分类</span>
        <el-input
          size="medium"
          v-model="form"
          placeholder="请输入分类"
        ></el-input>
        <div>
          <el-button size="medium" @click="resetForm">重置</el-button>
          <el-button size="medium" type="primary" @click="enquiry"
            >查询</el-button
          >
        </div>
      </div>
      <el-button size="medium" type="danger" @click="newNews">新增</el-button>
    </div>

    <!-- 中间表格区域 -->
    <el-table :data="data" border>
      <el-table-column
        label="创建时间"
        prop="createTime"
        width="180"
      ></el-table-column>
      <el-table-column
        label="新闻分类"
        prop="typeName"
        width="250"
      ></el-table-column>
      <el-table-column label="序号" prop="sort" width="250"></el-table-column>
      <el-table-column label="备注" prop="remark" width="250"></el-table-column>
      <el-table-column label="状态" width="180">
        <template slot-scope="scope">
          {{ scope.row.store ? "启用" : "关闭" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="time" width="">
        <template slot-scope="scope">
          <div class="operation">
            <div @click="chakan(scope.$index, scope.row.id)">查看</div>
            <div @click="xiugai(scope.$index, scope.row.id)">修改</div>
            <div @click="handleDelete(scope.$index, scope.row.id)">删除</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import closingPriceVue from "../digitalCertificate/closingPrice.vue";
export default {
  data() {
    return {
      form: "",
      data: [],
      totalItems: 0,
      pageSize: 10,
      currentPage4: null,
    };
  },
  created() {
    this.onNesClassification();
  },
  methods: {
    onNesClassification() {
      this.axios
        .get("/admin/consultType/findAll", {})
        .then((res) => {
          // console.log(res);
          this.data = res.data.data.records;
          this.totalItems = res.data.data.total;
          this.currentPage4 = res.data.data.current;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //   重置
    resetForm() {
      this.form = "";
      this.onNesClassification();
    },
    // 查询
    enquiry() {
      this.axios
        .get("/admin/consultType/findAll", {
          typeName: this.form,
        })
        .then((res) => {
          console.log(res);
          this.data = res.data.data.records;
          this.totalItems = res.data.data.total;
          this.currentPage4 = res.data.data.current;
        });
    },
    // 删除
    handleDelete(index, id) {
      console.log(index, id);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .delete(`/admin/consultType/delConsultType?ids=${id}`, {})
            .then((res) => {
              if (res.data.data) {
                this.data.splice(index, 1);
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            })
            .catch((err) => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // 跳转修改分类
    xiugai(index, id) {
      console.log(id);
      this.$router.push({ path: "addCategoryee", query: { id } });
    },
    // 跳转查看分类
    chakan(index, id) {
      console.log(id);
      this.$router.push({ path: "addCategoryccc", query: { id } });
    },
    // 跳转新闻分类新增
    newNews() {
      // alert("新增新闻分类");
      this.$router.push("addCategory");
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    span {
      width: 80px;
    }
    div {
      margin: 0 10px;
    }
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
